import React from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link'
import Layout from '../layouts/Index';
import SEO from '../components/SEO';

const NotFoundPage = ({ data: { staticText }, pageContext }) => {
  const staticPrismicTexts = staticText.edges[0].node.data.texts;
  const _t = (str) => staticPrismicTexts[staticPrismicTexts.findIndex(item => item.key_text === str)].text.text;
  return (
    <Layout
      twic={pageContext.twic}
      context={pageContext}
      _t={staticPrismicTexts}
    >
      <SEO context={pageContext} />
      <div className="page demo">
        <section className="regular">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/">Wakatoon</Link>
            </div>
            <div 
              className="page-head no-padding no-custom-style"
            >
              <h2 style={{ padding: '100px 0' }}>404 {_t(`Page non trouvée`)}</h2>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
};

export default NotFoundPage;
export const pageQuery = graphql`
  query notFoundPageFrQuery($locale: String!) {
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;